// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-head{
    color: #F5A200;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700; 
    font-size: 25px;
 }
 .termsecond{
    color:#132F41;
    font-weight: 700; 
    font-size: 18px;
    font-family: "Nunito Sans", sans-serif;
 }
 .texthird{
    color:#181818;
    font-weight: 700; 
   font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
 }
 .termsp{
   color: #6B6B6B;
   font-family: "Nunito Sans", sans-serif;
   font-size: 15px;
 }
 .emailtext{
    color: #F5A200;
 }`, "",{"version":3,"sources":["webpack://./src/components/Termscondition/terms.css"],"names":[],"mappings":"AAEC;IACG,cAAc;IACd,sCAAsC;IACtC,gBAAgB;IAChB,eAAe;CAClB;CACA;IACG,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,sCAAsC;CACzC;CACA;IACG,aAAa;IACb,gBAAgB;GACjB,eAAe;IACd,sCAAsC;CACzC;CACA;GACE,cAAc;GACd,sCAAsC;GACtC,eAAe;CACjB;CACA;IACG,cAAc;CACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200..1000&display=swap');\n \n .terms-head{\n    color: #F5A200;\n    font-family: \"Nunito Sans\", sans-serif;\n    font-weight: 700; \n    font-size: 25px;\n }\n .termsecond{\n    color:#132F41;\n    font-weight: 700; \n    font-size: 18px;\n    font-family: \"Nunito Sans\", sans-serif;\n }\n .texthird{\n    color:#181818;\n    font-weight: 700; \n   font-size: 16px;\n    font-family: \"Nunito Sans\", sans-serif;\n }\n .termsp{\n   color: #6B6B6B;\n   font-family: \"Nunito Sans\", sans-serif;\n   font-size: 15px;\n }\n .emailtext{\n    color: #F5A200;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
