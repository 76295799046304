import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../Assets/framlogo.png";

function Authenticateotp() {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axios.post('https://api.juristbot.ai/verifyotp', { otp });
            console.log(response.data);
            toast.success('OTP verified successfully!');
            setTimeout(() => {
                navigate('/');
            }, 3000); 
        } catch (error) {
            setError('Failed to verify OTP. Please try again.');
            toast.error('Failed to verify OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid backimage4">
            <ToastContainer />
            <div className="row">
                <div className="col-lg-5 col-md-5 backimageforget">
                    <img src={logo} alt="logo" className="logoimagereg" />
                    <h1 className="homeforgettex">
                        Welcome to Juristbot AI -<br />
                        Your AI assistant Legal<br /> Council
                    </h1>
                </div>
                <div className="col-lg-7 col-md-7">
                    <div className='container-fluid '>
                        <div className="registrationforget">
                            <form onSubmit={handleLogin}>
                                <div>
                                    <h3 className='passhead'>Enter the OTP</h3>
                                </div>
                                <div className='mt-4'>
                                    <input
                                        type="text"
                                        className="reg-formlog"
                                        placeholder='Enter your OTP'
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        required
                                    />
                                </div>
                                <button className='regformbtn1 mt-3' type="submit" disabled={loading}>
                                    {loading ? 'Verifying...' : 'Continue'}
                                </button>
                                {error && <p className="text-danger text-center">{error}</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Authenticateotp;
