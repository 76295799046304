import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSpinner,faArrowUp,faEllipsisVertical,faQuestion,} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Typist from "react-typist";
import pdf from "../Assets/orangepdf.png";
import logo from "../Assets/logo-law.png";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import pdfdownlode from "../Assets/Downloadicon.png";
import key from "../Assets/key.png";
import userhelp from "../Assets/user.png";
import { Link } from "react-router-dom";
import "./dash.css";

const Judgement = () => {
  const userid = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [messages, setMessages] = useState([]);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [formattedText, setFormattedText] = useState("");
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [helpOpen, sethelpOpen] = useState(false);
  const menuRef = useRef();
  const chatContainerRef = useRef();
  const handlehelp = () => {
    sethelpOpen(!helpOpen);
  };
  const handleClicfaQ = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      sethelpOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClicfaQ);
    return () => {
      document.removeEventListener("mousedown", handleClicfaQ);
    };
  }, []);

  useEffect(() => {
    setSendDisabled(textInput.trim() === "" && !fileInput);
  }, [textInput, fileInput]);

  useEffect(() => {
    if (messages.length > 0) {setTextInput("");}
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) { scrollToBottom(); }
  }, [messages]);

  useEffect(() => {
    if (formattedText) {
      const newMessages = [...messages];
      newMessages.push({
        sender: "bot",
        message: formattedText,
        copied: false,
      });
      setMessages(newMessages);
      setFormattedText("");
    }
  }, [formattedText, messages]);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (!textInput.trim() && !fileInput) {
        console.log("Please enter a message or upload a file.");
        return;
      }
      setLoading(true);
      const newMessages = [...messages];
      newMessages.push({ sender: "you", message: textInput, copied: false });
      setMessages(newMessages);

      const formData = new FormData();
      formData.append("judgement", textInput);
      formData.append("userid", userid);

      const response = await fetch(`https://py.juristbot.ai/law_bot`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.text) {
        setFormattedText(data.text);
      }

      setMessages(newMessages);
      setTextInput("");
      setFileInput(null);
      setLoading(false);

      scrollToBottom();
    } catch (error) {
      setLoading(false);
    }
  };

const copyMessage = async (message, index) => {
    try {
      await navigator.clipboard.writeText(message);
      const newMessages = [...messages];
      newMessages[index].copied = true;
      setMessages(newMessages);
      setTimeout(() => {
        const resetMessages = [...messages];
        resetMessages[index].copied = false;
        setMessages(resetMessages);
      }, 2000);
    } catch (error) {
      console.error("Error copying message:", error);
    }
  };

  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  const handleKeyPress = (e) => {
    if (!isMobileDevice() && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const shareMessageByEmail = (message) => {
    const encodedMessage = encodeURIComponent(message);
    window.location.href = `mailto:?body=${encodedMessage}`;
  };

  const handlePrint = (message) => {
    const printWindow = window.open("", "", "width=600,height=400");
    printWindow.document.write(`<pre>${message}</pre>`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    setTimeout(() => {
      printWindow.close();
    }, 1000);
  };

  const handleInviteClick = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDownloade = async ({pdfFilename }) => {
    try {
      setLoading(true);
        const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZVB6D4DGP",
          secretAccessKey: "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",
        },
      });
      const command = new GetObjectCommand({
        Bucket: "jurist-bot",
        Key: pdfFilename,
      });
      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", pdfFilename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setLoading(false);
    }
  };

  const [input, setInput] = useState("");
  const [messagess, setMessagess] = useState([]);

  const chatbotMessage = async () => {
    try {
      const formData = new FormData();
      formData.append("judgement_pdf", input);
      const response = await fetch(`https://py.juristbot.ai/law_bot`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      const newMessages = [...messagess, { mess: input }];
      if (data.doc) {
        const pdfFilename= data.doc;
        newMessages.push({
          mess: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img className="pdfjudement" src={pdf} alt="pdf" />
              <p style={{ margin: "0 10px" }}>Judgement copy pdf</p>
              <img
                onClick={() => handleDownloade({ pdfFilename })}
                className="downloadeicon"
                src={pdfdownlode}
                alt="Download PDF"
              />
            </div>
          ),
        });
      }
      setMessagess(newMessages);
      setInput("");
      setLoad(false);
    } catch (error) {
      console.error("Error", error);
      setLoad(false);
    }
  };
  
return (
    <div className="chat-container " ref={chatContainerRef}>
      <div className="message-containerdiv">
        {messages.length === 0 && (
          <div className="center-content">
            <img src={logo} alt="Logo" className="center-logo" />
            <p className="center-text">How can I help you?</p>
          </div>
        )}
        <div className="messages-container">
          {messages.map((message, index) => {
            if (message.sender === "bot") {
              return (
                <div key={index} className="message-container">
                  <div className={`message ${message.sender}`}>
                    {typeof message.message === "string" ? (
                      <Typist avgTypingDelay={4}  stdTypingDelay={7}  cursor={{ show: false }} >
                        <pre>
                          <img  className="boticon" src={require("../Assets/logo-chat.png")}  alt="tt"/>
                          {message.message}
                        </pre>
                      </Typist>
                    ) : (
                      <Typist onTypingDone={() => copyMessage(message.message, index)} >
                        {message.message}
                      </Typist>
                    )}
                    {message.copied ? (
                      <span class="material-symbols-outlined icon-copy1">check</span>
                    ) : (
                     <>
                      <span class="material-symbols-outlined icon-copy1"
                        onClick={() => copyMessage(message.message, index)} >
                        content_copy
                      </span>
                     </>
                    )}
                    <span class="material-symbols-outlined icon-share"
                      onClick={() => shareMessageByEmail(message.message)} >
                      share
                    </span>
                    <span class="material-symbols-outlined icon-print"
                      onClick={() => handlePrint(message.message)} >
                      print
                    </span>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={index} className={`message ${message.sender}`}>
                  {typeof message.message === "string" ? ( <div>{message.message}</div>) : ( message.message)}
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row  ">
        <div className="d-flex justify-content-center ">
        <form onSubmit={sendMessage} className="message-form">
         <textarea
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleKeyPress(e);
              }
            }}
            placeholder="Message Relevant Judgement..."
               
          ></textarea>
           <div className="icon-container">
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              onClick={handleInviteClick}
              className="icon-sent4"
            />
            <span className="tooltip-text">judgement copy</span>
          </div>
          <button
            type="submit"
            className="message-button"
            disabled={sendDisabled || loading} >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} className="icon-sent2" spin />
            ) : (
              <FontAwesomeIcon icon={faArrowUp} className="icon-sent1" />
            )}
          </button>
        </form>
        {open && (
          <div ref={menuRef} className="chatbot-menu">
            <div className="pdf-container">
              <h3 className="pdf-header">Judgement Download</h3>
              <div className="pdf-messages">
                {messagess.map((mes, id) => (
                  <div key={id} className="pdf-message">
                    <span>{mes.send} </span>
                    {mes.mess}
                  </div>
                ))}
              </div>
              <div className="pdf-input">
                <input type="text"  value={input}  onChange={(e) => setInput(e.target.value)}  placeholder="Type a message..."  />
                <button  className="pdf-input-button" onClick={chatbotMessage} disabled={load}>
                  {load ? "loading..." : "Send"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {helpOpen && (
          <div ref={menuRef} className="helpmenu">
              <Link to="/Terms" className="textecoraion">
                <h6 className=" helpmenu-p ">
                  <img src={userhelp} alt="help" />{" "}
                  <span className="help">Terms & Policies</span>
                </h6>
              </Link>
              <Link to="/help&FAQ" className="textecoraion">
                <h6 className="textkey ">
                  <img src={key} alt="key" />{" "}
                  <span className="helpmenu-text">Help & FAQ </span>{" "}
                </h6>
              </Link>
            </div>
        )}
        <FontAwesomeIcon
          onClick={handlehelp}
          icon={faQuestion}
          className="icon-qustion  fixed-bottom-left"
        />
        </div>
      </div>
    </div>
  </div>
  );
};
export default Judgement;
