// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: rgb(240, 240, 240);
}
.textcomming {
  font-size: 44px;
  color: #333;
  animation: blink-shadow 1s infinite;
}


.textcomming {
  position: absolute;
  background: linear-gradient(to right, #4d4d4d 0, rgb(212, 212, 212) 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-size: 34px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
}
@keyframes shine {
  0% {
    background-position: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  60% {
    background-position: 200px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  100% {
    background-position: 200px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/pages/comming.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,eAAe;EACf,WAAW;EACX,mCAAmC;AACrC;;;AAGA;EACE,kBAAkB;EAClB,qFAAqF;EACrF,sBAAsB;EACtB,6BAA6B;EAC7B,0BAA0B;EAE1B,qBAAqB;EACrB,oCAAoC;EACpC,mCAAmC;EACnC,6BAA6B;EAC7B,8BAA8B;EAC9B,eAAe;EACf,WAAW;EACX,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE;IACE,sBAAsB;IACtB,2CAA2C;EAC7C;EACA;IACE,0BAA0B;IAC1B,2CAA2C;EAC7C;EACA;IACE,0BAA0B;IAC1B,2CAA2C;EAC7C;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap\");\n.content {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  text-align: center;\n  background-color: rgb(240, 240, 240);\n}\n.textcomming {\n  font-size: 44px;\n  color: #333;\n  animation: blink-shadow 1s infinite;\n}\n\n\n.textcomming {\n  position: absolute;\n  background: linear-gradient(to right, #4d4d4d 0, rgb(212, 212, 212) 10%, #4d4d4d 20%);\n  background-position: 0;\n  -webkit-background-clip: text;\n  -moz-background-clip: text;\n  -ms-background-clip: text;\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n  animation: shine 4s infinite linear;\n  animation-fill-mode: forwards;\n  -webkit-text-size-adjust: none;\n  font-size: 34px;\n  color: #333;\n  text-decoration: none;\n  white-space: nowrap;\n}\n@keyframes shine {\n  0% {\n    background-position: 0;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n  }\n  60% {\n    background-position: 200px;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  100% {\n    background-position: 200px;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
