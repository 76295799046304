import React, { useState, useEffect } from 'react';
import './chat.css';

function ChatHistoryPage() {
  const [chatHistory, setChatHistory] = useState([]);
  const id = localStorage.getItem("userId");

  useEffect(() => {
    fetch(`http://localhost:3005/getChatHistory/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setChatHistory(data);
      })
      .catch((error) => {
        console.error('Error fetching chat history:', error);
      });
  }, [id]);

  return (
    <div className="chat-history-container">
      <h1>Chat History</h1>
      {chatHistory.length > 0 ? (
        <ul>
          {chatHistory.map((chat) => (
            <li key={chat.id} className="chat-item">
              <h3>{chat.Tittle}</h3>
              <p><strong>Created At:</strong> {new Date(chat.Created_at).toLocaleString()}</p>
              <div className="chat-section">
                <h4>Human Messages:</h4>
                <ul>
                  {chat.humanmessage.messages.map((msg, index) => (
                    <li key={index}>{msg}</li>
                  ))}
                </ul>
              </div>
              <div className="chat-section">
                <h4>Responses:</h4>
                <ul>
                  {chat.response.responses.map((resp, index) => (
                    <li key={index}>{resp}</li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-data">No chat history found for this user.</p>
      )}
    </div>
  );
}

export default ChatHistoryPage;
