import React from 'react';
import './comming.css'

function Comminsoon() {
  return (
    <div className="content">
    <h1 className="textcomming">Coming Soon</h1>
  </div>
  )
}

export default Comminsoon