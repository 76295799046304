import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../Assets/framlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./setpassword.css";

function SetpasswordForm() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "otp") setOtp(value);
    if (name === "password") setPassword(value);
    if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordError(password !== value ? "Passwords do not match" : "");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post("https://api.juristbot.ai/verifyotp&password", {
        otp,
        password,
      });
      console.log(response.data);
      toast.success("Password update successful");
  
      setTimeout(() => {
        navigate("/");
      }, 3000);
      
    } catch (error) {
      console.error("Error submitting form:", error.response ? error.response.data : error);
      toast.error("Enter correct otp! Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="container-fluid backimage5">
      <div className="row">
        <div className="col-lg-5 col-md-5 backimagepass">
          <img src={logo} alt="logo" className="logoimagereg" />
          <h1 className="reghomesetpass">
            Welcome to Juristbot AI -
            <br />
            Your AI assistant Legal
            <br />
            Council
          </h1>
        </div>
        <div className="col-lg-7 col-md-7">
          <div className="container-fluid backtree">
            <div className="registrationsetpass">
              <h5 className="signup">Create new password</h5>
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="reglable">Enter OTP</label>
                  <input
                    className="reg-form"
                    type="text"
                    name="otp"
                    value={otp}
                    onChange={handleChange}
                    required
                    disabled={isLoading}
                  />
                </div>

                <div className="mt-3">
                  <h6 className="reglable">New Password</h6>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={password}
                      className="reg-form"
                      placeholder="New Password"
                      onChange={handleChange}
                      required
                      disabled={isLoading}
                    />
                    <span
                      className="password-toggle"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        className="eyepassword"
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </span>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 className="reglable">Confirm Password</h6>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={confirmPassword}
                      className="reg-form custom-inputs"
                      placeholder="Confirm New Password"
                      onChange={handleChange}
                      required
                      disabled={isLoading}
                    />
                    <span
                      className="password-toggle"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        className="eyepassword"
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </span>
                  </div>
                  {passwordError && (
                    <p className="text-danger">{passwordError}</p>
                  )}
                </div>
                <button
                  className="regformbtn1 mt-3"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Set Password"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SetpasswordForm;
