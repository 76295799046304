import React, { useState } from "react";
import "./help.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import helo from "../Assets/framlogo.png";
import axios from "axios";
import { Link } from "react-router-dom";

function Help() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
  };
  const toggleAccordion5 = () => {
    setIsOpen5(!isOpen5);
  };
  const toggleAccordion6 = () => {
    setIsOpen6(!isOpen6);
  };
  const toggleAccordion7 = () => {
    setIsOpen7(!isOpen7);
  };
  const toggleAccordion8 = () => {
    setIsOpen8(!isOpen8);
  };
  const toggleAccordion9 = () => {
    setIsOpen9(!isOpen9);
  };

  const [reportDetails, setReportDetails] = useState("");
  const [message, setMessage] = useState("");
  const user_id = localStorage.getItem('userId');
  const username = localStorage.getItem('username');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!reportDetails.trim()) {
        setMessage("Report details cannot be empty.");
       
        return;
      }

    const formData = {
      user_id,
      username,
      report_details: reportDetails
    };

    try {
      const response = await axios.post("https://api.juristbot.ai/user-report", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.error) {
        setMessage(response.data.message);
      } else {
        setMessage(response.data.message);
        setReportDetails("")
      }
    } catch (error) {
      setMessage("There was an error sending the report.");
    }
  };

  const isError = message === "Report details cannot be empty." || message === "There was an error sending the report.";
  return (
    <div className="container">
     <Link to="/scenario"><img src={helo} className="helplogo mt-3" alt="help logo"></img></Link> 
      <div className="row">
        <div className="col-lg-1 col-0"> </div>
        <div className="col-lg-10 col-12">
          <h1 className="helphead pt-3">
            Frequently Asked Questions
            <br /> Accordions For Jurist bot{" "}
          </h1>
          <div className="container mt-4">
      <form className="message1-form" onSubmit={handleSubmit}>
        <input
          className="input23"
          type="text"
          value={reportDetails}
          onChange={(e) => setReportDetails(e.target.value)}
          placeholder="Describe your issue"
        />
        <button type="submit" className="message1-button">
          Report
        </button>
         
      </form>
     <div  className="text-center text-success"> {message && <p className={isError ? "error-message" : ""}>{message}</p>}</div> 
    </div>
        </div>
        <div className="col-lg-1 col-0"> </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12">
          <div className=" container mt-1">
            <h6 className="general ">1. General Questions</h6>
            <div className="container">
              <div className="row mt-4  accordiondiv  ">
                <div className="col-lg-1"> </div>
                <div className="col-lg-10  ">
                  <div className="accordion">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">What is Juristbot?</h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen ? "rotate" : ""}`}
                            icon={isOpen ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion}
                            aria-expanded={isOpen}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Juristbot is an AI-powered legal assistant designed
                            to help legal professionals and the public access
                            legal information, draft documents, and retrieve
                            case judgments efficiently.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            Who can use Juristbot?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen1 ? "rotate" : ""}`}
                            icon={isOpen1 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion1}
                            aria-expanded={isOpen1}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen1 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Juristbot is useful for lawyers, judges, police
                            officers, and anyone seeking legal information or
                            assistance.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            How does Juristbot help with legal drafting?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen2 ? "rotate" : ""}`}
                            icon={isOpen2 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion2}
                            aria-expanded={isOpen2}
                          />
                        </div>
                      </div>
                      <div
                        className={`accordion-collapse collapse ${
                          isOpen2 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Juristbot generates precise legal drafts within
                            seconds, incorporating suitable acts and sections
                            relevant to your needs.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            Can Juristbot provide case judgments?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen3 ? "rotate" : ""}`}
                            icon={isOpen3 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion3}
                            aria-expanded={isOpen3}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen3 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Yes, Juristbot can provide relevant case judgments
                            from higher courts, including the Supreme Court and
                            High Courts.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            What is the cross-question feature?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen4 ? "rotate" : ""}`}
                            icon={isOpen4 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion4}
                            aria-expanded={isOpen4}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen4 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            The cross-question feature helps users formulate
                            effective questions for cross-examining witnesses,
                            accused individuals, plaintiffs, and defendants.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            How does the scenario solver work?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen5 ? "rotate" : ""}`}
                            icon={isOpen5 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion5}
                            aria-expanded={isOpen5}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen5 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            The scenario solver answers any legal questions or
                            scenarios you present, offering reliable and
                            accurate legal advice.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            Is Juristbot suitable for non-lawyers?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen6 ? "rotate" : ""}`}
                            icon={isOpen6 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion6}
                            aria-expanded={isOpen6}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen6 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Yes, Juristbot is designed to be user-friendly and
                            accessible to anyone seeking legal information.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            How quickly can Juristbot generate a legal draft?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen7 ? "rotate" : ""}`}
                            icon={isOpen7 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion7}
                            aria-expanded={isOpen7}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen7 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Juristbot can generate a legal draft within seconds.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            What types of legal documents can Juristbot draft?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen8 ? "rotate" : ""}`}
                            icon={isOpen8 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion8}
                            aria-expanded={isOpen8}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen3 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Juristbot can draft a variety of legal documents,
                            including contracts, petitions, affidavits, and
                            more.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion mt-2">
                    <div className="generalacardination">
                      <div className="row">
                        <div className="text-start col-lg-11 col-md-10 col-8 mt-1">
                          <h6 className="headtexthelp">
                            Is Juristbot updated with the latest legal
                            information?
                          </h6>
                        </div>
                        <div className=" col-lg-1 col-md-2 col-4 chevronbutton">
                          <FontAwesomeIcon
                            className={`chevron ${isOpen9 ? "rotate" : ""}`}
                            icon={isOpen9 ? faChevronDown : faChevronRight}
                            onClick={toggleAccordion9}
                            aria-expanded={isOpen9}
                          />
                        </div>
                      </div>

                      <div
                        className={`accordion-collapse collapse ${
                          isOpen9 ? "show" : ""
                        }`}
                      >
                        <div className="accadtext-body">
                          <h6 className="accadtext">
                            Yes, Juristbot is regularly updated with the latest
                            legal information and case law.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-1 "> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4"></div>
    </div>
  );
}

export default Help;
