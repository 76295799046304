import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import logo from "../Assets/framlogo.png";
import "./login.css";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await axios.post(`https://api.juristbot.ai/login`, {
        email,
        pass_word: password,
      });
      const { id, username, role } = response.data;
      toast.success("Login Successful");
      const userResponse = await axios.get(`https://api.juristbot.ai/user/${id}`);
      
      const userData = userResponse.data.data;
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('userId', id); 
      localStorage.setItem('username', username); 
      localStorage.setItem('role', role); 

      setTimeout(() => {
        navigate("/scenario");
      }, 100);
      
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error("Network error. Try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="container-fluid backimage3">
      <div className="row">
        <div className="col-lg-5 col-md-5 backimagelogin">
          <img src={logo} alt="logo" className="logoimagereg" />
          <h1 className="homelogintex">
            Welcome to Juristbot AI -
            <br />
            Your AI assistant Legal
            <br /> Council
          </h1>
        </div>
        <div className="col-lg-7 col-md-7">
          <div className="container-fluid">
            <div className="registration-Loginin">
              <h4 className="signup">Login</h4>
              <form onSubmit={handleLogin}>
                <div>
                  <label className="reglable mt-2">Email</label>
                  <input
                    type="email"
                    className="reg-form mt-1"
                    value={email}
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="password-input-container mt-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    className="reg-form"
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="password-toggle" onClick={togglePasswordVisibility}>
                    <FontAwesomeIcon className="eyepassword" icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
                <button
                  className="regformbtn1 mt-3"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Logging in..." : "Login"}
                </button>
                <p className="text-center mt-2 reglable">
                  Don’t have an account?
                  <Link to="/register">
                    <span className="colorlogin"> Sign up</span>
                  </Link>
                </p>
                <p className="text-center colorlogin">
                  <Link to="/forgetpassmobile" className="colorlogin">
                    Forgot password?
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}
export default LoginForm;
