import React, { useState, useEffect } from "react";
import linkedin from "../assetes/images/linkedin.svg";
import instagram from "../assetes/images/instagram.svg";
import twiter from "../assetes/images/twiter.svg";
import facebook from "../assetes/images/facebook.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import absteact from "../assetes/images/absteact.svg";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobileno: "",
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!formData.name) {
      formIsValid = false;
      newErrors.name = "First name is required.";
    } else if (formData.name.length > 50) {
      formIsValid = false;
      newErrors.name = "First name cannot exceed 50 characters.";
    }

    if (!formData.email) {
      formIsValid = false;
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formIsValid = false;
      newErrors.email = "Email is invalid.";
    }

    if (!formData.message) {
      formIsValid = false;
      newErrors.message = "Message is required.";
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      sendMessageTemplate(formData);
    }
  };

  const sendMessageTemplate = async (formData) => {
    try {
      const response = await fetch("http://localhost:3005/send-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Message sent successfully!");
        setFormData({
          name: "",
          mobileno: "",
          email: "",
          message: "",
        });
      } else {
        alert("Failed to send the message. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="container text-center"
      data-aos="flip-left"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="700"
    >
      <h6
        className="font-family"
        style={{
          fontWeight: "700",
          textAlign: "left",
        }}
      >
        <img
          src={absteact}
          style={{
            height: "25px",
            marginRight: "8px",
          }}
          alt="abstract"
        />{" "}
        Contact
      </h6>
      <h5
        className="font-family"
        style={{
          fontSize: "42px",
          fontWeight: "600",
          textAlign: "left",
          borderBottom: "1px solid #f5f5f5",
        }}
      >
        Contact <span style={{ color: "#FFD119" }}>Us</span>
      </h5>
      <p
        className="font-family con"
        style={{
          textAlign: "left",
          fontSize: "16px",
          color: "#858585",
        }}
      >
        Fill out the form below, and one of our friendly team members will get
        back to you shortly.
      </p>
      <div className="col-12 d-flex min-contact">
        <form
          onSubmit={handleSubmit}
          className="bg-colors col-lg-7 col-sm-12 col-md-12"
          style={{
            padding: "35px",
            border: "1px solid",
            borderRadius: "10px",
          }}
        >
          <div
            className="row justify-content-center"
            style={{
              display: "flex",
              justifyContent: "flex-start !important",
            }}
          >
            <div className="col-12">
              <div className="mb-3">
                <label className="font-family form-data-cls">First Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control contact-info"
                  placeholder="Enter first name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="font-family form-data-cls">
                  Mobile Number
                </label>
                <input
                  type="text"
                  name="mobileno"
                  className="form-control contact-info"
                  placeholder="Enter Mobile Number"
                  value={formData.mobileno}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label
                  className="font-family"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "700",
                    float: "left",
                    padding: "12px 0px",
                  }}
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control contact-info form-data-cls"
                  placeholder="Enter your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label
                  className="font-family"
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "700",
                    float: "left",
                    padding: "12px 0px",
                  }}
                >
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control contact-info form-data-cls"
                  rows="4"
                  placeholder="Enter your message here"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                type="submit"
                className="Contact-btn send-btn"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Your Message"}
              </button>
            </div>
          </div>
        </form>
        <div className="col-lg-5 col-md-12 col-sm-12 media-container">
          <div className="d-flex">
            <a href="https://www.linkedin.com/company/104347295/admin/dashboard/" target="_blank" rel="noreferrer" >
            <img
              className="media-img"
              src={linkedin}
              style={{
                padding: "11px",
                marginLeft: "0px",
              }}
              alt="LinkedIn"
            /></a>
           <a href="https://www.instagram.com/jurist_bot_ai/" target="_blank" rel="noreferrer" > <img
              className="media-img"
              src={instagram}
              style={{
                padding: "10px",
              }}
              alt="Instagram"
            /></a>
            <a href="https://x.com/juristbot883431/" target="_blank" rel="noreferrer" ><img
              className="media-img"
              src={twiter}
              style={{
                padding: "13px",
              }}
              alt="Twitter"
            /></a>
           <a href="https://www.facebook.com/profile.php?id=61563380994834/" target="_blank" rel="noreferrer"> <img
              className="media-img"
              src={facebook}
              style={{
                padding: "11px",
              }}
              alt="Facebook"
            /></a>
          </div>
          <div className="col-12 d-flex sub-div-container">
            <div className="col-8" style={{ padding: "10px 12px" }}>
              <p className="mgb-5 sdiv-style_1">You can email us here</p>
              <p className="mgb-5 sdiv-style">
                <a className="emailsdiv" href="mailto:support@juristbot.ai">
                  support@juristbot.ai
                </a>
              </p>
            </div>
            <div className="col-4 arrow-right">
              <a className="emailsdiv" href="mailto:support@juristbot.ai">
                {" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ color: "#fdd639", height: "18px" }}
                />
              </a>
            </div>
          </div>
          <div className="col-12 d-flex sub-div-container">
            <div className="col-8" style={{ padding: "10px 12px" }}>
              <p className="mgb-5 sdiv-style_1">Give us a call on</p>
              <p className="mgb-5 sdiv-style">
                {" "}
                <a className="emailsdiv" href="tel:+919655442477">
                  +91 96-55-44-2477{" "}
                </a>
              </p>
            </div>
            <div className="col-4 arrow-right">
              <a className="emailsdiv" href="tel:+919655442477">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ color: "#fdd639", height: "18px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
