import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCoins,  faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import upgrade from "../Assets/upgrade.png";
import axios from "axios";

function Navbar() {
  const [visible, setVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const userRole = localStorage.getItem("role");
  const [showScenarioHistory, setShowScenarioHistory] = useState(false);
  const [showLegalHistory, setShowLegalHistory] = useState(false);
  const [showCrossQAHistory, setShowCrossQAHistory] = useState(false);
  const [showJudgementHistory, setShowJudgementHistory] = useState(false);
  const [scenarios, setScenarios] = useState([]);
  const [legaldrafting, setLegaldrafting] = useState([]);
  const [crossqussion, setCrossqussion] = useState([]);
  const [judgement, setJudgement] = useState([]);
  const navigate = useNavigate();
  const username = localStorage.getItem("username") || "Guest";

  const menuRef = useRef();
  const navref = useRef();

  const toggleScenarioHistory = () => setShowScenarioHistory(!showScenarioHistory);
  const toggleLegalHistory = () => setShowLegalHistory(!showLegalHistory);
  const toggleCrossQAHistory = () => setShowCrossQAHistory(!showCrossQAHistory);
  const toggleJudgementHistory = () => setShowJudgementHistory(!showJudgementHistory);
  const handleLogoutClick = () => setMenuOpen(!menuOpen);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
    if (navref.current && !navref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTitleClick = (user_id) => {
    navigate(`/chat-history/${user_id}`);
  };



  const fetchScenarios = async () => {
    const id = localStorage.getItem("userId");
    try {
      const response = await axios.get(`http://localhost:3005/getChatHistory/${id}`);
      console.log(response)
      setScenarios(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("Scenarios not found for this user");
      } 
    }
  };

  const fetchLegaldrafting = async () => {
    const id = localStorage.getItem("userId");
    try {
      const response = await axios.get(`https://api.juristbot.ai/legaldrafting/${id}`);
      setLegaldrafting(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("Legal drafts not found for this user");
      }
    }
  };

  const fetchCrossqussion = async () => {
    const id = localStorage.getItem("userId");
    try {
      const response = await axios.get(`https://api.juristbot.ai/crossqussion/${id}`);
      setCrossqussion(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("Cross Q/As not found for this user");
      } 
    }
  };
  const fetchjudgment = async () => {
    const id = localStorage.getItem("userId");
    try {
      const response = await axios.get(`https://api.juristbot.ai/judgement/${id}`);
      setJudgement(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("Cross Q/As not found for this user");
      } 
    }
  };

  useEffect(() => {
    fetchScenarios();
    fetchLegaldrafting();
    fetchCrossqussion();
    fetchjudgment();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    navigate("/login");
  };

  const refreshPage = () => { window.location.reload();};
  
  return (
    <>
      <div className="top-nav">
        <img
          src={require("../Assets/align-left.png")}
          alt="logo"
          className="mobile-nav-btn"
          onClick={() => setVisible(!visible)}
        />
        <img src={require("../Assets/framlogos.png")} alt="logo" className="top-logo" />
        
        <div className="profile">
        <div className="profile">  
        {(userRole === "lawyer"  || userRole === "Advocate" || userRole === "chartedAccount"|| userRole === "Auditor") && (
            <Link className="linktagupgrade" to="/Earning"><button className="earnbtn">Earn<FontAwesomeIcon  className="coins" icon={faCoins}/></button></Link>
        )}    
        </div>
          <div className="profileimage">
            <h6 className="firstletterchar" onClick={handleLogoutClick}>
            {username ? username.charAt(0) : "G"}
            </h6>
          </div>
          <span className="usernames">{username}</span>
        </div>
        {menuOpen && (
          <div ref={menuRef} className="menulogout" onClick={() => setMenuOpen(false)}>
           {(userRole === "lawyer"  || userRole === "Advocate" || userRole === "chartedAccount"|| userRole === "Auditor" ) && (  <div className="termsnav">
              <Link className="linktagupgrade" to="/Earning">
              <h6 className="text-center mb-0 navtext2"> Earn<FontAwesomeIcon  className="coins" icon={faCoins}/></h6>
            </Link></div>  )} 
            <div className="logout-button" onClick={handleLogout}>
            <h6 className="text-center mb-1"> Log out</h6>
            </div>
            <div className="termsnav">
              <Link to="/help&FAQ" className="navtext2">
                <h6 className="text-center mb-1">FaQ & help</h6>
              </Link>
            </div>
            <div className="termsnav">
              <Link to="/Terms" className="navtext2">
                <h6 className="text-center mb-1">Terms & Policies</h6>
              </Link>
            </div>
          </div>
        )}
      </div>

      <nav ref={navref} className={visible ? "navbars open" : "navbars"}>
        <div className="nav-top">
          {(userRole === "lawyer" || userRole === "judge" || userRole === "police" || userRole === "public" || userRole === "Advocate" || userRole === "chartedAccount"|| userRole === "Auditor") && (
            <Link className="navtext1 nav1-link mt-2" to="/scenario">
              <h6 className="navtext1">
                Scenario Solver
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="chevrandown"
                  onClick={toggleScenarioHistory}
                />
                <span className="pentosquericon" onClick={refreshPage}>
                  <FontAwesomeIcon className="pentosquericon" icon={faPenToSquare} />
                </span>
              </h6>
            </Link>
          )}
          {showScenarioHistory && (
            <div className="history-section">
                {scenarios.map((scenario) => (
                  <p className="texthistory1" key={scenario.id} onClick={() => handleTitleClick(scenario.User_Id)}>
                    {scenario.Tittle}
                    <span className="trashicon">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </p>
                ))}
              </div>
          )}
          {(userRole === "lawyer"  || userRole === "Advocate"|| userRole === "chartedAccount"|| userRole === "Auditor") && (
            <Link className="navtext1 nav1-link mt-2" to="/LegalDrafting">
              <h6 className="navtext1">
                Legal Drafting
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="chevrandown1"
                  onClick={toggleLegalHistory}
                />
                <span className="pentosquericon" onClick={refreshPage}>
                  <FontAwesomeIcon className="pentosquericon" icon={faPenToSquare} />
                </span>
              </h6>
            </Link>
          )}
          {showLegalHistory && (
            <div className="history-section">
                {legaldrafting.map((legaldrafting) => (
                  <p className="texthistory1" key={legaldrafting.id}>
                    {legaldrafting.title}
                    <span className="trashicon">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </p>
                ))}
              </div>
          )}
          {(userRole === "lawyer"  || userRole === "Advocate"|| userRole === "chartedAccount"|| userRole === "Auditor") && (
            <Link className="navtext1 nav1-link" to="/CrossQussion">
              <h6 className="navtext1">
                Cross Q/A
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="chevrandown2"
                  onClick={toggleCrossQAHistory}
                />
                <span className="pentosquericon" onClick={refreshPage}>
                  <FontAwesomeIcon className="pentosquericon" icon={faPenToSquare} />
                </span>
              </h6>
            </Link>
          )}
          {showCrossQAHistory && (
            <div className="history-section">
                {crossqussion.map((crossqussion) => (
                  <p className="texthistory1" key={crossqussion.id}>
                    {crossqussion.title}
                    <span className="trashicon">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </p>
                ))}
              </div>  
          )}
          {(userRole === "lawyer" || userRole === "judge" || userRole === "Advocate"|| userRole === "chartedAccount"|| userRole === "Auditor") && (
            <Link className="navtext1" to="/judgement">
              <h6 className="nav1-link">
                Relevant Judgement
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="chevrandown3"
                  onClick={toggleJudgementHistory}
                />
                <span className="pentosquericon" onClick={refreshPage}>
                  <FontAwesomeIcon className="pentosquericon" icon={faPenToSquare} />
                </span>
              </h6>
            </Link>
          )}
          {showJudgementHistory && (
            <div className="history-section">
                {judgement.map((judgement) => (
                  <p className="texthistory1" key={judgement.id}>
                    {judgement.title}
                    <span className="trashicon">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </p>
                ))}
              </div>
          )}
        </div>
        {userRole !== "public" && userRole !== "judge" && userRole !== "police"  && (
          <div className="profile-section">
            <Link className="linktagupgrade" to="/Subscribtion">
              <div className="upgrade-container">
                <img className="upgradeimg" src={upgrade} alt="Upgrade" />
                <span className="text-light">Upgrade Plan</span>
              </div>
            </Link>
          </div>
        )}
      </nav>
    </>
  );
}
export default Navbar;
