// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChatHistoryPage.css */

.chat-history-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .chat-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
  }
  
  .chat-item h3 {
    margin-top: 0;
    color: #0056b3;
  }
  
  .chat-item p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 5px;
  }
  
  .chat-section h4 {
    margin: 10px 0 5px 0;
    color: #333;
  }
  
  .no-data {
    text-align: center;
    font-size: 16px;
    color: #888;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/chathistory/chat.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb","sourcesContent":["/* ChatHistoryPage.css */\n\n.chat-history-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  h1 {\n    text-align: center;\n    color: #333;\n    margin-bottom: 20px;\n  }\n  \n  .chat-item {\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    margin-bottom: 20px;\n    padding: 15px;\n    background-color: #f9f9f9;\n  }\n  \n  .chat-item h3 {\n    margin-top: 0;\n    color: #0056b3;\n  }\n  \n  .chat-item p {\n    font-size: 14px;\n    color: #666;\n    margin-bottom: 10px;\n  }\n  \n  ul {\n    padding-left: 20px;\n  }\n  \n  ul li {\n    margin-bottom: 5px;\n  }\n  \n  .chat-section h4 {\n    margin: 10px 0 5px 0;\n    color: #333;\n  }\n  \n  .no-data {\n    text-align: center;\n    font-size: 16px;\n    color: #888;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
