import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Earning.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import hh from "../Assets/noti.png";
import axios from "axios";
import draftsData from "../config/drafts.json";

function Earning() {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userid = localStorage.getItem("userId");
  const username = localStorage.getItem("username");
  const [walletData, setWalletData] = useState({ coupon_amt: 0 });
  const [notifications, setNotifications] = useState([]);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [maindrafts, setMaindrafts] = useState([]);
  const [subdrafts, setSubdrafts] = useState([]);
  const [selectedMainDraftName, setSelectedMainDraftName] = useState("");
  const [selectedSubDraftName, setSelectedSubDraftName] = useState("");
  const menuRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsNotificationMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileUpload = () => {
    document.getElementById("formFileLg").click();
  };

  const handleBellClick = () => {
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size < 10485760) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      toast.error("File size should be less than 10 MB.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }
    if (!selectedMainDraftName) {
      toast.error("Please enter a draft name.");
      return;
    }
    if (!selectedSubDraftName) {
      toast.error("Please enter a draft name.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("maindraft_name", selectedMainDraftName);
    formData.append("subdraft_name", selectedSubDraftName);
    formData.append("user_id", userid);
    formData.append("userName", username);
    try {
      const response = await axios.post(
        "https://api.juristbot.ai/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      toast.success("File uploaded successfully!");
      setFileName("");
      setSelectedMainDraftName("");
      setSelectedSubDraftName("");
      setFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response = await axios.get(
          `https://api.juristbot.ai/wallet/${userid}`
        );
        setWalletData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWalletData();
  }, [userid]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `https://api.juristbot.ai/status/${userid}`
        );
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
  }, [userid]);

  useEffect(() => {
    setMaindrafts(draftsData.maindrafts || []);
  }, []);

  const handleMainDraftChange = (event) => {
    const mainDraftId = parseInt(event.target.value);
    const selectedMainDraft = draftsData.maindrafts.find(
      (draft) => draft.id === mainDraftId
    );
    setSelectedMainDraftName(selectedMainDraft.name);
    setSubdrafts(selectedMainDraft ? selectedMainDraft.subdrafts : []);
  };

  const handleSubDraftChange = (event) => {
    setSelectedSubDraftName(event.target.value);
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid bodyearning">
        <div className="row earningnav">
          <div className="col-10 col-lg-11 col-md-11 col-sm-11 p-0">
            <Link to="/scenario">
              <img
                src={require("../Assets/framlogo.png")}
                alt="logo"
                className="toplog"
              />
            </Link>
          </div>
          <div className="col-2 col-lg-1 col-md-1 col-sm-1">
            <FontAwesomeIcon
              className="notificationicon p-0"
              icon={faBell}
              onClick={handleBellClick}
            />
          </div>
          {isNotificationMenuOpen && (
            <div ref={menuRef} className="notification-menu">
              <div className="headnotification">
                <h6 className="mb-0">Your Notification</h6>
              </div>
              <div className="mainnotification">
                {notifications.map((notification, index) => (
                  <div key={index}>
                    {notification.satus === "Accepted" && (
                      <>
                        <div className="notification-item">
                          <img
                            src={hh}
                            alt="logo"
                            className="notification-logo"
                          />
                          <span className="notification-item-span">
                            Your documents were accepted
                          </span>
                        </div>
                        <div className="">
                          <h6 className="notification-status">
                            Here you have received
                            <span className="notiamtcolor">100</span> rupees.
                          </h6>
                        </div>
                      </>
                    )}
                    {notification.satus === "Rejected" && (
                      <>
                        <div className="notification-item">
                          <img
                            src={hh}
                            alt="logo"
                            className="notification-logo"
                          />
                          <span className="notification-item-span">
                            Your documents were rejected
                          </span>
                        </div>
                        <div className="">
                          <h6 className="notification-status">
                            This PDF is not valid.
                          </h6>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="footer-notification">
                <span className="footer-notification-span ">
                  <i className="bi bi-info-circle"></i> This rupees can be used
                  only for Jurist bot
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="earningpdf">
            <h5 className="textearning mt-3">
              Earning amount:<span  className="amountcolor">
                  {walletData.coupon_amt}
                </span>
             
            </h5>
            <h5 className="textearnhead mt-4">Earn by uploading documents</h5>
            <div className="row">
              <div className="col-lg-2 col-1"></div>
              <div className="col-lg-8 col-10">
                <form onSubmit={handleSubmit}>
                  <div className="mt-2 mb-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleMainDraftChange}
                    ><option value="" disabled selected>
                    Choose Main draft menu
                  </option>
                      {maindrafts.map((draft) => (
                        <option key={draft.id} value={draft.id}>
                          {draft.name}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-select mt-2"
                      aria-label="Default select example"
                      onChange={handleSubDraftChange}
                    >
                      <option value="" disabled selected>
                        Choose sub draft menu
                      </option>
                      {subdrafts.map((subdraft, index) => (
                        <option key={index} value={subdraft}>
                          {subdraft}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="formFileLg"
                      className="form-label hidden-file-input"
                    >
                      Upload File
                    </label>
                    <input
                      className="form-control form-control-lg hidden-file-input"
                      id="formFileLg"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <div className="upload-button-container mb-0">
                      <div className="upload-button" onClick={handleFileUpload}>
                        Upload file{" "}
                        <span>
                          <FontAwesomeIcon
                            className="icon-thick"
                            icon={faCloudArrowUp}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      {fileName && (
                        <p className="filename-display mt-1 mb-1">{fileName}</p>
                      )}
                    </div>
                    <div className="save-earnbtn-container ">
                      <button
                        type="submit"
                        className="save-earnbtn mt-3"
                        disabled={isLoading}
                      >
                        {isLoading ? "Uploading..." : "Save"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-2 col-1"></div>
              <p className="mb-2 bottomtext">
                File review process usually takes 24-48 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Earning;
