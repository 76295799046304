import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import logo from "../Assets/framlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "../register/register.css";

function RegistrationForm() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile_no: "",
    pass_word: "",
    role: "Advocate"
  });
  const [error, setError] = useState({
    passwordError: "",
    mobileError: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;

    if (formData.pass_word.length < 8) {
      setError(prev => ({ ...prev, passwordError: "Password must be at least 8 characters long." }));
      valid = false;
    } else if (!/\d/.test(formData.pass_word) || !/[!@#$%^&*]/.test(formData.pass_word)) {
      setError(prev => ({ ...prev, passwordError: "Password must include at least one number and one special character." }));
      valid = false;
    } else {
      setError(prev => ({ ...prev, passwordError: "" }));
    }

    if (!/^\d+$/.test(formData.mobile_no) || formData.mobile_no.length !== 10) {
      setError(prev => ({ ...prev, mobileError: "Mobile number must be a 10-digit number." }));
      valid = false;
    } else {
      setError(prev => ({ ...prev, mobileError: "" }));
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(`https://api.juristbot.ai/signup`, formData);
      console.log("Registration successful", response.data);
      toast.success("Registration successful!", {});
      setTimeout(() => {
        navigate('/verifyotp');
      }, 1000);
    } catch (error) {
      console.error("There was an error registering the user!", error);
      if (error.response) {
        if (error.response.data.message === 'Email already exists') {
          toast.error("Email already exists. Please use a different email.", {});
        } else {
          toast.error(`Error: ${error.response.data.message}`, {});
        }
      } else {
        toast.error("There was an error registering the user!", {});
      }
    }
  };

  return (
    <div className="container-fluid backimage2">
      <ToastContainer />
      <div className="row">
        <div className="col-lg-5 col-md-5 backimage1">
          <img src={logo} alt="logo" className="logoimagereg" />
          <h1 className="reghometex">
            Welcome to Juristbot AI - <br /> Your AI assistant Legal  <br /> Council
          </h1>
        </div>
        <div className="col-lg-7 col-md-7">
          <div className="container-fluid">
            <div className="registration-registersce">
              <h4 className="signup">Sign up</h4>
              <form onSubmit={handleSubmit}>
                <div className="mt-1">
                  <label className="reglable">UserName</label>
                  <input
                    className="reg-form"
                    type="text"
                    name="username"
                    placeholder="Enter your name.."
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mt-1">
                  <label className="reglable">Email</label>
                  <input
                    className="reg-form"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mt-1">
                  <label className="reglable">Mobile number</label>
                  <input
                    className="reg-form"
                    type="text"
                    name="mobile_no"
                    placeholder="Enter your mobile number"
                    value={formData.mobile_no}
                    onChange={handleChange}
                    required
                  />
                  {error.mobileError && (
                    <p className="error-message">{error.mobileError}</p>
                  )}
                </div>
                <div className="mt-1">
                  <label className="reglable">Role</label>
                  <select
                    className="reg-form"
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    required
                  >
                    <option value="Advocate">Advocate</option>
                    <option value="lawyer">lawyer</option>
                    <option value="chartedAccount">chartedAccount</option>  
                    <option value="Auditor">Auditor</option>  
                    <option value="police">police</option>
                    <option value="judge">judge</option>
                    <option value="public">public</option>   
                  </select>
                </div>
                <div className="mt-1">
                  <label className="reglable">Password</label>
                  <div className="password-input-container mt-1">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="reg-form"
                      placeholder="Enter your password"
                      name="pass_word"
                      value={formData.pass_word}
                      onChange={handleChange}
                      required
                    />
                    <span className="password-toggle" onClick={togglePasswordVisibility}>
                      <FontAwesomeIcon className="eyepassword" icon={showPassword ? faEyeSlash : faEye} />
                    </span>
                  </div>
                  {error.passwordError && (
                    <p className="error-message">{error.passwordError}</p>
                  )}
                </div>
                <button className="regformbtn1 mt-3" type="submit">
                  Sign up
                </button>
                <p className="text-center pt-2 reglable ptextlogin">
                  Already have an account?
                  <Link to="/login">
                    <span className="colorlogin"> Log in </span>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RegistrationForm;
