import './juristbotterm.css';
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
export const Legalterm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
 

    const toggleAccordion = () => { setIsOpen(!isOpen); };
    const toggleAccordion1 = () => { setIsOpen1(!isOpen1); };
  
  return (
    <div className='container'>

<div className='row mt-5'>

    <div className=' container mt-1'>
        <h6 className='general1 '>2. About Jurist Bot</h6>
        <div className='container mt-4 accordiondiv1'>
        <div className='row'>
          <div className='col-lg-1 col-md-0 col-0'></div>
             <div className='col-lg-10 col-md-12 col-12'>
             <div className='row  '>
             <div className='col-lg-3 col-md-6 col-12 text-center mt-1 '><Link to="/help&FAQ" className='bttlink'> <button  className=' text-ceneter snario-button '>Scenario solver</button></Link></div>
                  <div className='col-lg-3 col-md-6 col-12 text-center mt-1'><Link to="/help&FAQ/legaldraft"  className='bttlink'><button  className=' text-ceneter snario-button '>Legal drafting</button></Link></div>
                  <div className='col-lg-3 col-md-6 col-12  text-center mt-1'><Link to="/help&FAQ/crossqusionterms"  className='bttlink'><button  className=' text-ceneter snario-button'>Cross Q/A</button></Link></div>
                  <div className='col-lg-3 col-md-6 col-12  text-center mt-1'><Link to="/help&FAQ/Releventterms"  className='bttlink'> <button  className=' text-ceneter snario-button'>Relavent Judgement</button></Link> </div>
            </div>
             </div>
          <div className='col-lg-1 col-md-0 col-0'></div>
        </div>
        <div className='row  mt-4 '>
           <div className='col-lg-1 '>{' '}</div>
            <div className='col-lg-10 col-md-10 col-12 accordion123  '>
                
                    <div className="generalacardination1">
                    
                        <div className='row'>
                            <div className='text-start col-lg-11 col-md-10 col-8 mt-1'> <h6 className='headtexthelps'>What services Legal  Drafting Bot provide?</h6></div>
                            <div className=' col-lg-1 col-md-2 col-4 chevronbutton1'>
                                <FontAwesomeIcon
                                    className={`chevron1 ${isOpen ? 'rotate1' : ''}`}
                                    icon={isOpen ? faMinus:faPlus}
                                    onClick={toggleAccordion}
                                    aria-expanded={isOpen}
                                />
                            </div>
                        </div>

                        <div className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}>
                            <div className="accadtext-body1">
                                <h6 className='accadtext1'>
                                Generate precise legal drafts instantly, complete with appropriate acts and sections. Save time and ensure accuracy in your legal documents.
                                </h6>
                            </div>
                        </div>
                   
                </div>
                <div className=" mt-2">
                <div className="generalacardination1">
                        <div className='row'>
                            <div className='text-start col-lg-11 col-md-10 col-8 mt-1'> <h6 className='headtexthelps'>Can Juristbot assist in legal research? </h6> </div>
                            <div className=' col-lg-1 col-md-2 col-4 chevronbutton1'>
                                <FontAwesomeIcon
                                    className={`chevron1 ${isOpen1 ? 'rotate1' : ''}`}
                                    icon={isOpen1 ? faMinus:faPlus}
                                    onClick={toggleAccordion1}
                                    aria-expanded={isOpen1}
                                />
                            </div>
                        </div>

                        <div className={`accordion-collapse collapse ${isOpen1 ? 'show' : ''}`}>
                            <div className="accadtext-bod1y">
                                <h6 className='accadtext1'>
                                Absolutely, Juristbot is a valuable tool for conducting legal research quickly and efficiently.
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <div className='col-lg-1 '>{' '}</div>
        </div>
        </div>
    </div>
</div>
</div>

  )
}

