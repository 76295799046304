// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;CACC,UAAU;CACV,SAAS;CACT,sBAAsB;CACtB,oCAAoC;AACrC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');\n\n*{\n\tpadding: 0;\n\tmargin: 0;\n\tbox-sizing: border-box;\n\tfont-family: 'Open Sans', sans-serif;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
