import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import './tokencs.css';

export const Tokensterms = () => {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6,setIsOpen6] = useState(false);
   

    const toggleAccordion1 = () => { setIsOpen1(!isOpen1); };
    const toggleAccordion2 = () => { setIsOpen2(!isOpen2); };
    const toggleAccordion3 = () => { setIsOpen3(!isOpen3); };
    const toggleAccordion4 = () => { setIsOpen4(!isOpen4); };
    const toggleAccordion5 = () => { setIsOpen5(!isOpen5); };
    const toggleAccordion6 = () => { setIsOpen6(!isOpen6); };
   
    

    return (
        <div className='container'>
            <div className='row mt-5'>
                <div className='container mt-1'>
                    <h6 className='tokengeneral'>3. Tokens and subscriptions</h6>
                    <div className='container mt-4 tokensdiv'>
                        <div className='row '>
                            <div className='col-lg-12   '>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2'>
                                        <div className='row generalacardination1'>
                                            <div className='text-start col-lg-10 col-md-10 col-8 mt-1'><h6 className='headtexthelps'> Why Subscribe to Juristbot? </h6></div>
                                            <div className=' col-lg-2 col-md-2 col-4 chevronbutton1'>
                                                <FontAwesomeIcon
                                                    className={`chevron2 ${isOpen1 ? 'rotate2' : ''}`}
                                                    icon={isOpen1 ? faMinus : faPlus}
                                                    onClick={toggleAccordion1}
                                                    aria-expanded={isOpen1}
                                                />
                                            </div>
                                            <div className={`accordion-collapse collapse ${isOpen1 ? 'show' : ''}`}>
                                                <div className="accadtext-body1">
                                                    <h6 className='accadtext1'>
                                                    Subscribing to Juristbot grants you access to a powerful AI  bot
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2'>
                                        <div className='row generalacardination1'>
                                            <div className='text-start col-lg-10 col-md-10 col-8 mt-1'> <h6 className='headtexthelps'> Is there a subscription fee for Juristbot?</h6></div>
                                            <div className=' col-lg-2 col-md-2 col-4 chevronbutton1'>
                                                <FontAwesomeIcon
                                                    className={`chevron2 ${isOpen2 ? 'rotate2' : ''}`}
                                                    icon={isOpen2 ? faMinus : faPlus}
                                                    onClick={toggleAccordion2}
                                                    aria-expanded={isOpen2}
                                                />
                                            </div>
                                            <div className={`accordion-collapse collapse ${isOpen2 ? 'show' : ''}`}>
                                                <div className="accadtext-body1">
                                                    <h6 className='accadtext1'>
                                                    Yes, subscribing to Juristbot involves a fee, which provides access to its comprehensive range of features.
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2'>
                                        <div className='row generalacardination1'>
                                            <div className='text-start col-lg-10 col-md-10 col-8 mt-1'><h6 className='headtexthelps'>  How do I subscribe to Juristbot?</h6></div>
                                            <div className=' col-lg-2 col-md-2 col-4 chevronbutton1'>
                                                <FontAwesomeIcon
                                                    className={`chevron2 ${isOpen3 ? 'rotate2' : ''}`}
                                                    icon={isOpen3 ? faMinus : faPlus}
                                                    onClick={toggleAccordion3}
                                                    aria-expanded={isOpen3}
                                                />
                                            </div>
                                            <div className={`accordion-collapse collapse ${isOpen3 ? 'show' : ''}`}>
                                                <div className="accadtext-body1">
                                                    <h6 className='accadtext1'>
                                                    You can subscribe to Juristbot through our website. Simply choose a subscription plan that suits your needs and follow the registration process.
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2'>
                                        <div className='row generalacardination1'>
                                            <div className='text-start col-lg-10 col-md-10 col-8 mt-1'><h6 className='headtexthelps'> How do I view my token balance and subscription details?</h6></div>
                                            <div className=' col-lg-2 col-md-2 col-4 chevronbutton1'>
                                                <FontAwesomeIcon
                                                    className={`chevron2 ${isOpen4 ? 'rotate2' : ''}`}
                                                    icon={isOpen4 ? faMinus : faPlus}
                                                    onClick={toggleAccordion4}
                                                    aria-expanded={isOpen4}
                                                />
                                            </div>
                                            <div className={`accordion-collapse collapse ${isOpen4 ? 'show' : ''}`}>
                                                <div className="accadtext-body1">
                                                    <h6 className='accadtext1'>
                                                    You can view your current token balance and subscription details in your account dashboard. This section will show your remaining tokens, subscription status, and upcoming billing dates.                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2'>
                                        <div className='row generalacardination1'>
                                            <div className='text-start col-lg-10 col-md-10 col-8 mt-1'> <h6 className='headtexthelps'> services does Lawyer Bot provide?</h6></div>
                                            <div className=' col-lg-2 col-md-2 col-4 chevronbutton1'>
                                                <FontAwesomeIcon
                                                    className={`chevron2 ${isOpen5 ? 'rotate2' : ''}`}
                                                    icon={isOpen5 ? faMinus : faPlus}
                                                    onClick={toggleAccordion5}
                                                    aria-expanded={isOpen5}
                                                />
                                            </div>
                                            <div className={`accordion-collapse collapse ${isOpen5 ? 'show' : ''}`}>
                                                <div className="accadtext-body1">
                                                    <h6 className='accadtext1'>
                                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2'>
                                        <div className='row generalacardination1'>
                                            <div className='text-start col-lg-10 col-md-10 col-8 mt-1'><h6 className='headtexthelps'>  How do subscriptions work?</h6></div>
                                            <div className=' col-lg-2 col-md-2 col-4 chevronbutton1'>
                                                <FontAwesomeIcon
                                                    className={`chevron2 ${isOpen6 ? 'rotate2' : ''}`}
                                                    icon={isOpen6 ? faMinus : faPlus}
                                                    onClick={toggleAccordion6}
                                                    aria-expanded={isOpen6}
                                                />
                                            </div>
                                            <div className={`accordion-collapse collapse ${isOpen6 ? 'show' : ''}`}>
                                                <div className="accadtext-body1">
                                                    <h6 className='accadtext1'>
                                                    When you subscribe to a plan, you will be billed automatically at the start of each billing cycle (3 month and 6 month). Depending on the plan, you will receive a set number of tokens or unlimited access to certain services for the duration of the billing period.                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4'></div>
        </div>
    );
}
